<template>
  <div class="videoItem" v-if="index<9" v-bind:title="item.title" v-on:click="jump">
    <div class="top">
      <div class="left">
        <img v-bind:src="item.pic" />
      </div>
      <div class="right">
        <div class="title">
          {{ item.title }}
        </div>
        <div class="content">
          {{ item.desc }}
        </div>
      </div>
    </div>
    <div class="bottom">
      <div>
        <i class="el-icon-time"></i>
        {{ time }}
      </div>
      <div>
        评分：{{ item.score }}
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'VideoItem',
    props: [ 'item', 'index' ],
    data: function(){
      return {
        time: null
      }
    },
    mounted() {
      if(this.item.pubdate){
        this.time = moment(this.item.pubdate).format('YYYY-MM-DD HH:mm:ss');
      }else {
        this.time = moment().format('YYYY-MM-DD HH:mm:ss');
      }
    },
    methods: {
      jump(){
        window.location.href = this.item.short_link;
      }
    }
  }
</script>

<style scoped lang="scss">
  .videoItem{
    width: 380px;
    height: 150px;
    background: white;
    margin-top: 30px;
    border-radius: 10px;
    cursor: pointer;
    .top{
      margin: 10px;
      display: flex;
      .left{
        img{
          width: 130px;
          border-radius: 10px;
        }
      }
      .right{
        margin-left: 10px;
        .title{
          width: 220px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        .content{
          margin-top: 5px;
          width: 220px;
          word-break: break-all;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          color: #888;
          font-style: italic;
        }
      }
    }
    .bottom{
      display: flex;
      justify-content: space-between;
      margin: 20px 10px 10px 10px;
      color: #888;
      font-size: 12px;
    }
  }
</style>
