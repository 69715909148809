import Vue from 'vue'
import VueRouter from 'vue-router'
import Homepage from '../pages/Homepage'
import Form from '../pages/Form'
import Note from '../pages/Note'
import Share from '../pages/Share'
import Interest from '../pages/Interest'
import Cartoon from '../pages/Cartoon'
import Blibli from '../pages/Blibli'
import Friends from '../pages/Friends'
import Article from '../pages/Article'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: Homepage
  },
  {
    path: '/form',
    name: 'Form',
    component: Form
  },
  {
    path: '/note',
    name: 'Note',
    component: Note
  },
  {
    path: '/share',
    name: 'Share',
    component: Share
  },
  {
    path: '/interest',
    name: 'Interest',
    component: Interest
  },
  {
    path: '/cartoon',
    name: 'Cartoon',
    component: Cartoon
  },
  {
    path: '/blibli',
    name: 'Blibli',
    component: Blibli
  },
  {
    path: '/friends',
    name: 'Friends',
    component: Friends
  },
  {
    path: '/article/:id',
    name: 'Article',
    component: Article
  }
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
