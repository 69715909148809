<template>
  <div class="nav" ref="nav" id="nav" v-bind:class="{'darkMode': projectStatus == 'night'}">
    <!--<img src="../assets/bg2.gif"/>-->
    <Menu v-bind:name="name" v-on:search="doSearch"></Menu>
    <div class="content">
      <div class="avatar" v-if="cat != 5">
        <img v-bind:src="avartar" id="userImg">
        <!--<el-avatar shape="square" :size="130" v-bind:src="avartar"></el-avatar>-->
      </div>
      <div class="userName" >
        <template v-if="cat == 0">
          {{ name || 'XXXXX' }}
        </template>
        <template v-if="cat == 1">
          笔记专题
        </template>
        <template v-if="cat == 2">
          兴趣活动
        </template>
        <template v-if="cat == 3">
          分享记录
        </template>
        <template v-if="cat == 4">
          朋友圈
        </template>
        <template v-if="cat == 5">
          {{ title || 'XXXXX' }}
        </template>
      </div>
      <div class="description">
        <template v-if="cat == 0">
          {{ content || '这人很懒，什么都没有留下' }}
        </template>
        <template v-if="cat == 1">
          这里可以留下你的笔记哦
        </template>
        <template v-if="cat == 2">
          你有什么兴趣可以在这里说说哦
        </template>
        <template v-if="cat == 3">
          你有什么需要分享的可以留在这里哦
        </template>
        <template v-if="cat == 4">
          这里住着朋友们
        </template>
        <template v-if="cat == 5">
          <div v-html="miniTitle"></div>
        </template>
      </div>
    </div>
    <div class="bottom">
    </div>
    <div class="bottom" style="opacity: 0.7">
    </div>
    <canvas id="canvas"></canvas>
  </div>
</template>

<script>
import Menu from './menu/Menu';
import {mapGetters} from "vuex";
let canvas,
        canvasHeight = 600,
        ctx,
        num,
        color,
        radius,
        speed,
        ballX,
        ballY,
        reX,
        reY,
        ballK = [],
        realTimeClData;
export default {
  name: 'Head',
  props: {
    cat: Number,
    title: String,
    miniTitle: String
  },
  data: function(){
    return {
      background: null,
      avartar: null,
      name: null,
      content: null,
    }
  },
  computed: {
    ...mapGetters([
      'projectStatus'
    ])
  },
  components:{
    Menu
  },
  mounted() {
    this.initCanvas();
    realTimeClData = setInterval(this.move, 20);
    this.avartar = this.$imagesUrl + 'user.png';
    this.$rq({
      method: 'get',
      url: '/user'
    }).then((res) => {
      let response = res.data;
      if(response.res){
        let data = response.data;
        this.name = data.name;
        this.content = data.content;
        this.background = this.$imagesUrl + data.background;
        //console.log('zyx', response.data, this.background, document.getElementById('nav'));
        document.getElementById('nav').style.backgroundImage = "url("+this.background+")";
        let userUrl = this.$imagesUrl + data.avartar;
        if(document.getElementById('userImg')){
          document.getElementById('userImg').src = userUrl;
        }
      }
    })
  },
  beforeDestroy: function () {
    clearInterval(realTimeClData);
  },
  methods:{
    initCanvas() {
      canvas = document.getElementById("canvas");
      ctx = canvas.getContext("2d");
      canvas.width = window.innerWidth - 20;

      canvas.height = canvasHeight;

      num = 500; // 定义气泡数量
      color = "rgba(255, 255, 255, 0.45)"; // 定义气泡颜色
      radius = []; // 定义气泡半径
      speed = []; // 小球向上移动速度
      ballX = []; // 球心的横坐标
      ballY = []; // 球心的纵坐标

      // 在 dom 底部产生随机半径小球
      for (let i = 0; i < num; i++) {
        let r = Math.random(50, 100) * 5;
        let x = Math.floor(Math.random() * 2 * canvas.offsetWidth);
        let y = -10;
        let sp = Math.floor(Math.random() * 15);
        ballX.push(x);
        ballY.push(y);
        radius.push(r);
        speed.push(sp);
      }
    },
    move() {
      ctx.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight);
      for (let i = 0; i < num; i++) {
        (function (i) {
          if (ballK[i] == null) {
            ballK[i] = 0;
          }
          reX = ballK[i] * speed[i] + ballY[i];
          reY = Math.sin(ballK[i]) + ballX[i];
          if (reX + radius[i] <= 0) {
            ballY[i] = canvasHeight + 10;
            ballK[i] = 0;
            reX = ballK[i] * speed[i] + ballY[i];
          }
          ctx.beginPath();
          ctx.fillStyle = color;
          ctx.arc(reY, reX, radius[i], 0, Math.PI * 2);
          ctx.fill();
          ballK.splice(i, 1, ballK[i]);
          ballK[i] -= 0.1;
        })(i);
      }
    },

    doSearch(value){
      this.$emit('search', value);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .nav{
    width: 100%;
    height: 600px;
    background: url("../assets/bg4.png") no-repeat;
    background-size: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .menu{
      z-index: 3;
    }
    .content{
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 100px;
      flex-direction: column;
      z-index: 2;
      .avatar{
        background: rgba(0,0,0,0.7);
        padding: 10px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        img{
          width: 130px;
          height: 130px;
          border-radius: 50%;
        }
      }
      .userName{
        font-size: 30px;
        font-weight: bold;
        color: white;
        padding: 10px 0px;
      }
      .description{
        font-size: 16px;
        color: white;
      }
    }
    .bottom{
      width: 100%;
      height: 50px;
      background-image: linear-gradient(to top,#eee, rgba(255,255,255,0));
      position: absolute;
      bottom: 0px;
    }
    #canvas {
      top: 0px;
      z-index: 2;
      position: absolute;
      overflow: hidden;
    }
  }
  .darkMode{
    .content{
      background: rgba(0, 0, 0, 0.5);
    }
    .bottom{
      background-image: linear-gradient(to top,#2a2a2a, rgba(255,255,255,0));
    }
  }
  @media screen and (max-width: 1200px) {
    .nav{
      background-size: 100% 100%;
    }
  }
</style>
