<template>
  <div class="footer" v-bind:class="{'darkMode': projectStatus == 'night'}">
    Copyright © 2021 潇湘飞魂博客 <a href="https://beian.miit.gov.cn/">粤ICP备2022028295号</a>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: 'Footer',
    computed: {
      ...mapGetters([
        'projectStatus'
      ])
    },
  }
</script>


<style scoped lang="scss">
.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #3a3939;
  font-size: 14px;
  font-style: italic;
  a{
    margin-left: 10px;
    cursor: pointer;
    color: #3a3939;
    text-decoration: none;
    &:hover{
      color: #3368da;
    }
  }
}
.darkMode{
  background: #1a1a1a;
  color: white;
}
</style>
