<template>
  <div class="about" v-bind:class="{'darkMode': projectStatus == 'night'}">
    <Head v-bind:cat="0" v-on:search="doSearch"></Head>
    <div class="articleList" v-if="!isLoading">
      <Card v-for="(item,index) in articles" v-bind:key="index"
            v-bind:index="index" v-bind:article="item"></Card>
    </div>
    <div class="pagination" v-if="!isLoading">
      <el-button round v-on:click="getLastPage" v-if="page != 1">上一页</el-button>
      <el-button round v-on:click="getNextPage" style="margin-left: 30px" v-if="articles.length > 5">下一页</el-button>
    </div>
    <div v-if="isLoading" class="load">
      <i class="el-icon-loading"></i>
      <div>数据加载中......</div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
  // @ is an alias to /src
  import Head from '@/components/Head';
  import Card from '@/components/Card';
  import Footer from '@/components/Footer';
  import { mapGetters } from 'vuex';
  //import { mapMutations } from 'vuex';

  export default {
    name: 'Homepage',
    components: {
      Head,
      Card,
      Footer
    },
    data: function(){
      return {
        articles: [],
        page: 1,
        searchString: '',
        isLoading: true
      }
    },
    mounted() {
      this.getArticles();
      let info = navigator.userAgent;
      if(/mobile/i.test(info)){
        this.$notify({
          title: '提示',
          dangerouslyUseHTMLString: true,
          duration: 0,
          position: 'bottom-right',
          message: '<div>您好，您的设备的屏幕宽度过小，如果您是手机端打开的话可以使用微信小程序打开，搜索：<strong>潇湘飞魂</strong></div>' +
                  '<br/><div>或者扫描下面的二维码</div>' +
                  '<img style="width: 170px" src="https://www.zhangyunxin.club/images/xiaochengxu.jpg" />'
        });
      }
    },
    computed: {
      ...mapGetters([
        'projectStatus'
      ])
    },
    methods: {
      /*...mapMutations([
        'changeStatus'
      ]),*/
      getArticles(){
        this.isLoading = true;
        if(this.searchString){
          this.$rq({
            method: 'get',
            url: '/allArticles?cat=0&page='+this.page + '&string='+this.searchString
          }).then((res) => {
            console.log(res);
            this.isLoading = false;
            this.articles = res.data.data;
          })
        }else {
          this.$rq({
            method: 'get',
            url: '/articles?cat=0&page='+this.page
          }).then((res) => {
            console.log(res);
            this.isLoading = false;
            this.articles = res.data.data;
          })
        }
      },
      getLastPage(){
        this.page = this.page - 1;
        this.getArticles();
      },
      getNextPage(){
        this.page = this.page + 1;
        this.getArticles();
      },

      doSearch(value){
        this.searchString = value;
        this.page = 1;
        this.getArticles();
        console.log('search', value);
      }
    }
  }
</script>

<style scoped lang="scss">
.articleList{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #eee;
}
  .pagination {
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;

    .el-button.is-round {
      padding: 10px 30px;
    }
  }
  .load{
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #3a3939;
    background: #eee;
    i{
      font-size: 60px;
      margin-bottom: 20px;
    }
  }
.darkMode{
  .articleList{
    background: #2a2a2a;
  }
  .pagination{
    background: #2a2a2a;
  }
}
</style>
