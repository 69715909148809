<template>
  <div class="comment">
    <div class="left">
      <el-popover
              placement="top"
              width="200"
              trigger="manual"
              content="如果你是第一次留言，可以点击这里上传头像哦"
              v-model="visible">
        <!--<el-button>手动激活</el-button>-->
        <img slot="reference" id="yourImg" src="../assets/nouser.png" v-on:click="doUpload">
      </el-popover>
      <input type="file" style="display: none" v-on:change="fileUpload()" id="file" ref="fileBtn" accept="image/*"/>
    </div>
    <div class="right">
      <div class="row">
        <div class="title">
          <div class="line">
            <svg width="100%" height="100%" version="1.1"
                 xmlns="http://www.w3.org/2000/svg">
              <line x1="0" y1="14" x2="150" y2="14"
                    style="stroke:#ccc;stroke-width:2"/>
            </svg>
          </div>
          <div class="mini-title">
            评论区
          </div>
        </div>
      </div>
      <div class="row">
        <el-input placeholder="请输入昵称" v-model="name" v-on:blur="checkName">
          <template slot="prepend">昵称</template>
        </el-input>
        <el-input placeholder="请输入格言" v-model="desc">
          <template slot="prepend">格言</template>
        </el-input>
      </div>
      <div class="row">
        <el-input
                type="textarea"
                :rows="5"
                placeholder="你有什么想对我说的......"
                :autosize="false"
                v-model="message">
        </el-input>
      </div>
      <div class="row" style="justify-content: flex-end">
        <el-button type="primary" v-on:click="sendMessage">发送</el-button>
      </div>
    </div>
  </div>
</template>

<script>

  import $ from "jquery";
  import qs from "qs";

  export default {
    name: 'Comment',
    components: {
    },
    data: function () {
      return {
        visible: false,
        name: '',
        desc: '',
        message: '',
        timeId: null,
        avartar: null
      }
    },
    mounted() {
    },
    methods: {
      checkName(){
        this.$rq({
          method: 'get',
          url: '/friend?name='+this.name,
          //headers: {'contentType': 'application/x-www-form-urlencoded'}
        }).then((res) => {
          console.log('kkk', res);
          if(res.data.res){
            let data = res.data.data;
            if(data.avartar){
              document.getElementById('yourImg').src = this.$imagesUrl + data.avartar;
            }
            this.desc = data.description;
          }else {
            clearTimeout(this.timeId);
            this.visible = true;
            let that = this;
            this.timeId = setTimeout(function () {
              that.visible = false;
            }, 3000)
          }
        }).catch((err)=>{
          console.err(err);
          clearTimeout(this.timeId);
          this.visible = true;
          let that = this;
          this.timeId = setTimeout(function () {
            that.visible = false;
          }, 3000)
        })
      },
      doUpload(){
        document.getElementById('file').click();
      },
      fileUpload(){
        let name = $($('#file')).val();
        let files = $($('#file'))[0].files[0];
        let formData = new FormData();
        formData.append("file", files);
        formData.append("name", name);
        if(!files.name || !/(.png|.jpg|.gif)$/.test(files.name)){
          //alert('文件格式不对');
          this.$notify.error({
            title: '错误',
            message: '文件格式不对，请传png，jpg或gif格式的图片'
          });
          /*let oldInput = document.getElementById("file");
          let newInput = document.createElement("input");
          newInput.type = "file";
          newInput.id = oldInput.id;
          newInput.name = oldInput.name;
          newInput.className = oldInput.className;
          newInput.style.cssText = oldInput.style.cssText;
          oldInput.parentNode.replaceChild(newInput, oldInput);*/
          this.$refs['fileBtn'].value = '';
          return false;
        }
        let that = this;
        $.ajax({
          url: that.$baseUrl + 'userFile',
          type: 'POST',
          data: formData,
          processData: false,
          contentType: false,
          dataType: 'json',
          success:function (res) {
            console.log('zyx', res);
            if(res.res){
              console.log(that.$imagesUrl + res.file_name);
              document.getElementById('yourImg').src = that.$imagesUrl + res.file_name;
              that.avartar = res.file_name;
            }
            that.$refs['fileBtn'].value = '';
            that.$notify({
              title: '成功',
              message: '图片上传成功',
              type: 'success'
            });
          }
          ,error:function (res) {
            console.log('zyx', res);
            that.$refs['fileBtn'].value = '';
            that.$notify.error({
              title: '错误',
              message: '图片上传失败，请重新上传'
            });
          }
        });
        console.log(name, files);
      },
      sendMessage(){
        console.log('zyx', this.avartar, this.name, this.desc, this.message);
        if(this.name.trim() == ''){
          this.$notify({
            title: '提示',
            message: '昵称不能为空',
            type: 'warning'
          });
          return false;
        }
        if(this.desc.trim() == ''){
          this.$notify({
            title: '提示',
            message: '格言不能为空',
            type: 'warning'
          });
          return false;
        }
        if(this.message.trim() == ''){
          this.$notify({
            title: '提示',
            message: '留言不能为空',
            type: 'warning'
          });
          return false;
        }
        this.$rq({
          method: 'post',
          url: '/message',
          data: qs.stringify({
            avartar: this.avartar,
            name: this.name,
            description: this.desc,
            message: this.message
          }),
          //headers: {'contentType': 'application/x-www-form-urlencoded'}
        }).then((res) => {
          console.log('zyx', res)
          if(res.data.data){
            this.$notify({
              title: '成功',
              message: '留言提交成功',
              type: 'success'
            });
            this.message = '';
            this.$alert('谢谢你提交的留言，我抽时间看后会将你的留言显示出来哦！', '提示', {
              confirmButtonText: '确定',
              /*callback: action => {
                this.$message({
                  type: 'info',
                  message: `action: ${action}`
                });
              }*/
            })
            this.$emit('refresh', true);
          }else {
            this.$notify.error({
              title: '错误',
              message: '留言提交失败，请重新提交'
            });
          }
        }).catch((err)=>{
          console.err(err);
          this.$notify.error({
            title: '错误',
            message: '留言提交失败，请重新提交'
          });
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .comment{
    display: flex;
    flex-grow: 1;
    align-items: center;
    .left{
      margin-left: 20px;
      img{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .right{
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
      .row{
        margin: 10px;
        display: flex;
        justify-content: center;
        flex-grow: 1;
        width: 100%;
        .title{
          width: 150px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .line{
            position: absolute;
            top: 0px;
            left: 0px;
            z-index: -1;
            svg{
              width: 150px;
              height: 30px;
            }
          }
          .mini-title{
            z-index: 2;
            background: white;
            padding: 0px 10px 5px 10px;
            color: #999;
          }
        }
        .el-input-group{
          flex-grow: 1;
          margin: 0px 20px;
        }
        .el-textarea{
          flex-grow: 1;
          margin: 0px 20px;
        }
        .el-button{
          padding: 10px 20px;
          margin-right: 20px;
        }
      }
    }
  }
</style>
