<template>
  <div class="articleItem" v-bind:class="{'darkMode': projectStatus == 'night'}" v-if="index <= 4" v-on:click="$router.push('/article/'+article.id)">
    <div class="left" v-if="index%2 == 0">
      <img v-bind:src="picUrl">
    </div>
    <div class="right" v-bind:style="{'marginLeft':index%2 == 1?'0px':'20px',
    'marginRight':index%2 == 1?'20px':'0px'}">
      <div class="time">
        <div>
          <div class="clock">
            <svg t="1641461044014" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2800" width="200" height="200"><path d="M512.9 63.4C265.4 63.4 64.8 264 64.8 511.5 64.8 759 265.5 959.6 513 959.6c247.4 0 448-200.6 448-448.1C961 264 760.4 63.4 512.9 63.4zM737 560.9H499.1c-5 0-9.9-1-14.3-2.9-13.2-5.6-22.4-18.6-22.4-33.8V286.3c0-20.3 16.4-36.7 36.7-36.7 20.3 0 36.8 16.4 36.8 36.7v201.2h201.2c20.3 0 36.7 16.4 36.7 36.7 0 20.3-16.5 36.7-36.8 36.7z" fill="#459df5" p-id="2801"></path></svg>
          </div>
          <div class="update_time">
            {{ time }}
          </div>
        </div>
        <div>
          <div class="clock">
            <svg t="1641462351579" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3921" width="200" height="200"><path d="M463.6416 161.28a44.7552 44.7552 0 0 1 40.4032 12.2752l393.7216 393.7152a44.8 44.8 0 0 1 0 63.36l-262.4832 262.4768a44.8 44.8 0 0 1-63.36 0L178.2208 499.3856a44.7232 44.7232 0 0 1-12.8-37.0944V212.4864a51.2 51.2 0 0 1 51.2-51.2h247.0272zM381.856 359.104c24.9984-24.9984 24.9984-65.5232 0-90.5152-24.992-24.992-65.5168-24.992-90.5088 0-24.992 24.992-24.992 65.5168 0 90.5088 24.992 24.992 65.5168 24.992 90.5088 0z" fill="#50a107" p-id="3922"></path></svg>
          </div>
          <div class="update_time">
            <template v-if="article.cat == 1">
              笔记专题
            </template>
            <template v-else-if="article.cat == 2">
              兴趣话题
            </template>
            <template v-else>
              分享记录
            </template>
          </div>
        </div>
      </div>
      <div class="title">
        {{ article.title }}
      </div>
      <div class="content">
        {{ article.value }}
      </div>
    </div>
    <div class="left" v-if="index%2 == 1">
      <img v-bind:src="picUrl">
    </div>
  </div>
</template>

<script>
  import * as momemt from 'moment';
  import {mapGetters} from "vuex";
  export default {
    name: 'Homepage',
    props: ['index','article'],
    data: function(){
      return {
        picUrl: '',
        time: ''
      }
    },
    computed: {
      ...mapGetters([
        'projectStatus'
      ])
    },
    mounted() {
      this.picUrl = this.$imagesUrl + this.article.top_pic;
      this.time = momemt(this.article.update_time).format('YYYY-MM-DD HH:mm:ss');
      console.log('zyx', this.article, this.picUrl);
    }
  }
</script>

<style scoped lang="scss">
  .articleItem{
    min-width: 500px;
    width: 55%;
    min-height: 150px;
    background: rgba(65,65,65,1);
    margin-top: 15px;
    border-radius: 15px;
    padding: 30px 15px;
    display: flex;
    cursor: pointer;
    .left{
      img{
        width: 300px;
        border-radius: 15px;
        max-height: 200px;
      }
    }
    .right{
      margin-left: 20px;
      flex-grow: 1;
      .time{
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        >div{
          display: flex;
          align-items: center;
          .clock{
            display: flex;
            align-items: center;
            svg{
              width: 14px;
              height: 14px;
            }
          }
          .update_time{
            font-size: 12px;
            margin-left: 5px;
            color: white;
          }
        }
      }
      .title{
        font-size: 22px;
        color: white;
        font-weight: bold;
        margin: 10px 0px;
        font-style: italic;
      }
      .content{
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        color: white;
        margin: 10px 0px;
        letter-spacing: 3px;
        word-break: break-all;
      }
    }
  }
</style>
