<template>
  <div class="cartoonItem" v-on:click="jump()">
    <div class="left">
      <img v-bind:src="item.pic">
      <div>
        {{ item.title }}
      </div>
    </div>
    <div class="right">
      <div class="label">
        播放量：{{ item.play }}
      </div>
      <div class="label">
        评分数：{{ item.score }}
      </div>
    </div>
    <div class="king" v-if="index==0">
      <svg t="1641551227921" class="icon" viewBox="0 0 1138 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6342" width="200" height="200"><path d="M186.997713 866.592817L96.433117 325.992722l245.184301 204.225439 224.932418-358.162496 200.641538 358.219383 275.334574-204.282326-83.965668 540.600095H186.997713zM60.821662 269.503625a59.902336 59.902336 0 1 1 0-119.747785 59.902336 59.902336 0 0 1 0 119.747785zM569.8493 119.776228A59.902336 59.902336 0 1 1 569.8493 0.028444a59.902336 59.902336 0 0 1 0 119.747784z m508.970751 149.727397a59.902336 59.902336 0 1 1 0-119.747785 59.902336 59.902336 0 0 1 0 119.747785zM213.279649 1001.984612a28.443654 28.443654 0 1 1 0-56.887309h720.193328a28.443654 28.443654 0 0 1 0 56.887309H213.222762z" fill="#f5ea10" p-id="6343"></path></svg>
    </div>
    <div class="yin" v-if="index==1">
      <svg t="1641551725750" class="icon" viewBox="0 0 1138 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6729" width="200" height="200"><path d="M186.997713 866.592817L96.433117 325.992722l245.184301 204.225439 224.932418-358.162496 200.641538 358.219383 275.334574-204.282326-83.965668 540.600095H186.997713zM60.821662 269.503625a59.902336 59.902336 0 1 1 0-119.747785 59.902336 59.902336 0 0 1 0 119.747785zM569.8493 119.776228A59.902336 59.902336 0 1 1 569.8493 0.028444a59.902336 59.902336 0 0 1 0 119.747784z m508.970751 149.727397a59.902336 59.902336 0 1 1 0-119.747785 59.902336 59.902336 0 0 1 0 119.747785zM213.279649 1001.984612a28.443654 28.443654 0 1 1 0-56.887309h720.193328a28.443654 28.443654 0 0 1 0 56.887309H213.222762z" fill="#dbdbdb" p-id="6730"></path></svg>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CartoonItem',
    props: [ 'item', 'index' ],
    methods: {
      jump(){
        window.location.href = this.item.url;
      }
    }
  }
</script>

<style scoped lang="scss">
  .cartoonItem{
    width: 70%;
    height: 80px;
    border-radius: 10px;
    margin-top: 12px;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .left{
      display: flex;
      align-items: center;
      margin-left: 15px;
      img{
        width: 100px;
        border-radius: 10px;
      }
      >div{
        width:75%;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
    }
    .right{
      margin-right: 15px;
      color: white;
      font-size: 12px;
    }
    .king, .yin{
      position: absolute;
      right: -23px;
      top: -23px;
      z-index: 99;
      transform: rotate(35deg);
      svg{
        width: 50px;
        height: 50px;
      }
    }
  }
</style>
