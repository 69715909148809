import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        status: "day",
    },
    getters: {
        projectStatus(state) {
            return state.status;
        }
    },
    mutations:{
        changeStatus(state,status){
            state.status = status;
        }
    }
})
