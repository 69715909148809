<template>
  <div class="friends" v-bind:class="{'darkMode': projectStatus == 'night'}">
    <Head v-bind:cat="4"></Head>
    <div class="friendList" v-if="friendsList.length">
      <div class="friendItem" v-for="(item,index) of [0,1,2,3]"
           v-bind:key="index" v-bind:style="{'background': !friendsList[index]?'#efefef':'white'}">
        <template v-if="friendsList[index]">
          <div class="left">
            <img v-bind:src="friendsList[index].avartar">
          </div>
          <div class="right">
            <div class="name">
              {{ friendsList[index].name }}
            </div>
            <div class="desc">
              {{ friendsList[index].description }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <Comment v-on:refresh="doRefresh"></Comment>
    <div class="commentList" v-if="!isLoading">
      <div class="title">
        {{ commentList.length || 0 }} 条评论
      </div>
      <div class="commentItem" v-for="(item,index) in commentList" v-bind:key="index">
        <div class="left">
          <img v-bind:src="item.avartar">
        </div>
        <div class="right">
          <div class="mini-title">
            <div class="name">{{ item.name }}</div>
            <div class="time">{{ item.createtime }}</div>
          </div>
          <div class="message">
            {{ item.message }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="isLoading" class="load">
      <i class="el-icon-loading"></i>
      <div>数据加载中......</div>
    </div>
  </div>

</template>

<script>
  // @ is an alias to /src
  import Head from '@/components/Head';
  //import Card from '@/components/Card';
  import Comment from '@/components/Comment';
  import {mapGetters} from "vuex";

  export default {
    name: 'Homepage',
    components: {
      Head,
      //Card,
      //Footer
      Comment
    },
    data: function () {
      return {
        friendsList: [],
        commentList: [],
        isLoading: true
      }
    },
    computed: {
      ...mapGetters([
        'projectStatus'
      ])
    },
    mounted() {
      this.refreshList();
    },
    methods: {
      doRefresh(shouldDo){
        console.log('zyx', shouldDo);
        this.refreshList();
      },
      refreshList(){
        this.$rq({
          method: 'get',
          url: '/friendsList',
          //headers: {'contentType': 'application/x-www-form-urlencoded'}
        }).then((res) => {
          console.log('res', res);
          this.friendsList = res.data.data;
          this.friendsList.forEach((item) => {
            if(item.avartar){
              item.avartar = this.$imagesUrl + item.avartar;
            }else {
              item.avartar = this.$imagesUrl + 'nouser.png';
            }
          });
          //this.friendsList.reverse();
          this.friendsList.sort(function (a,b) {
            if(!b.createtime || !a.createtime){
              return 1;
            }else {
              return new Date(b.createtime).getTime() - new Date(a.createtime).getTime();
            }
          })
          this.friendsList = this.friendsList.filter((item) => {
            return item.isshow?true:false;
          })
        })
        this.isLoading = true;
        this.$rq({
          method: 'get',
          url: '/commentList',
          //headers: {'contentType': 'application/x-www-form-urlencoded'}
        }).then((res) => {
          this.commentList = res.data.data;
          this.commentList.forEach((item) => {
            if(item.avartar){
              item.avartar = this.$imagesUrl + item.avartar;
            }else {
              item.avartar = this.$imagesUrl + 'nouser.png';
            }
          });
          //this.commentList.reverse();
          this.commentList.sort(function (a,b) {
            if(!b.createtime || !a.createtime){
              return 1;
            }else {
              return new Date(b.createtime).getTime() - new Date(a.createtime).getTime();
            }
          })
          this.commentList = this.commentList.filter((item) => {
            return item.isshow?true:false;
          })
          this.isLoading = false;
          console.log('comment' , this.commentList);
        })
      }
    }
  }
</script>

<style scoped lang="scss">
.friendList{
  background: #efefef;
  padding: 30px 10px;
  display: flex;
  flex-grow: 1;
  .friendItem{
    flex-grow: 1;
    height: 100px;
    background: white;
    margin-right: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 300px;
    &:last-child{
      margin-right: 0px;
    }
    .left{
      display: flex;
      align-items: center;
      margin-left: 20px;
      img{
        width: 75px;
        height: 75px;
        border-radius: 50%;
      }
    }
    .right{
      margin-left: 15px;
      .name{
        font-size: 18px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        width: 150px;
      }
      .desc{
        color: #999;
        font-size: 14px;
        font-style: italic;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        width: 150px;
      }
    }
  }
}
  .commentList{
    display: flex;
    flex-direction: column;
    padding: 20px;
    .title{
      font-size: 22px;
      font-weight: bold;
    }
    .commentItem{
      margin: 20px 0px;
      display: flex;
      flex-grow: 1;
      .left{
        img{
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }
      .right{
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .mini-title{
          display: flex;
          justify-content: space-between;
          .time{
            font-size: 14px;
            color: #999;
            font-style: italic;
          }
        }
        .message{
          margin-top: 20px;
          color: #333;
        }
      }
    }
  }
.load{
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3a3939;
  background: #eee;
  i{
    font-size: 60px;
    margin-bottom: 20px;
  }
}
.darkMode{
  .friendList{
    background: #2a2a2a;
    .friendItem{
      background: #414141 !important;
    }
  }
  .comment{
    background: #2a2a2a;
    ::v-deep .mini-title{
      background: #2a2a2a !important;
    }
    ::v-deep .right{
      input{
        background: #414141;
        border-color: #1a1a1a;
      }
      .el-input-group__prepend{
        background: #2a2a2a;
        border-color: #1a1a1a;
      }
      textarea{
        background: #414141;
        border-color: #1a1a1a;
      }
    }
  }
  .commentList{
    background: #1a1a1a;
    .title{
      color: white;
    }
    .commentItem{
      .right{
        color: white;
      }
      .message{
        color: white;
      }
    }
  }
}
@media screen and (max-device-width: 1100px) {
  .friendList{
    flex-wrap: wrap;
    .friendItem{
      width: 400px;
      margin-bottom: 10px;
      margin-right: 10px;
      margin-left: 10px;
      &:last-child{
        margin-right: 10px;
      }
    }
  }
}
</style>
