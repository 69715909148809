<template>
  <div class="form" id="form">
    <div class="paper">
      <div class="reback" v-on:click="jumpToIndex()">
        <svg t="1641374291755" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3846" width="200" height="200"><path d="M549.952 65.824l4.384 3.52L992.544 456a32 32 0 0 1-17.44 55.776l-3.744 0.224H832v384a64 64 0 0 1-64 64H256a64 64 0 0 1-64-64V512H52.64a32 32 0 0 1-21.248-8.064l-2.752-2.752a32 32 0 0 1 0-42.336l2.816-2.848L469.664 69.376a64 64 0 0 1 80.32-3.552zM640 608h-256v256h256v-256z" p-id="3847" fill="#13227a"></path></svg>
      </div>
      <div class="title">
        个人信息修改
      </div>
      <div class="item bg">
        <div class="mini-title">背景：</div>
        <div class="mini-content">
          <div class="item-img" v-on:click="changeBg('bg4.png')" v-bind:class="{'choose':background=='bg4.png'}">
            <img src="../assets/bg4.png">
            <i class="el-icon-success"></i>
          </div>
          <div class="item-img" v-on:click="changeBg('bg5.png')" v-bind:class="{'choose':background=='bg5.png'}">
            <img src="../assets/bg5.png">
            <i class="el-icon-success"></i>
          </div>
          <div class="item-img" v-on:click="changeBg('bg2.gif')" v-bind:class="{'choose':background=='bg2.gif'}">
            <img src="../assets/bg2.gif">
            <i class="el-icon-success"></i>
          </div>
        </div>
      </div>
      <div class="item userImg">
        <div class="mini-title">头像：</div>
        <div class="mini-content">
          <div class="avartar">
            <img src="../assets/user.png" id="userImg">
          </div>
          <input type="file" v-on:change="fileUpload()" id="file" ref="fileBtn"/>
          <el-button class="fileBtn" v-on:click="doFileUpload" disabled>头像上传</el-button>
        </div>
      </div>
      <div class="item userName">
        <div class="mini-title">昵称：</div>
        <div class="mini-content">
          <el-input v-model="name" class="myName" placeholder="请输入昵称" v-on:change="isChangeUnwrittenInformation = true" disabled></el-input>
        </div>
      </div>
      <div class="item userName">
        <div class="mini-title">格言：</div>
        <div class="mini-content">
          <el-input v-model="content" type="textarea" class="myName description" placeholder="请输入格言" v-on:change="isChangeUnwrittenInformation = true" disabled></el-input>
        </div>
      </div>
      <div class="action">
        <el-button class="actionBtn" v-on:click="confirmInfo">确定</el-button>
        <el-button class="actionBtn" v-on:click="jumpToIndex()">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery';
  import qs from 'qs';
  export default {
    name: 'Form',
    data:function () {
      return {
        background: null,
        avartar: null,
        name: null,
        content: null,
        isChangeUnwrittenInformation: false
      }
    },
    mounted() {
      this.$rq({
        method: 'get',
        url: '/user'
      }).then((res) => {
        let response = res.data;
        if(response.res){
          let data = response.data;
          this.name = data.name;
          this.content = data.content;
          this.background = data.background;
          this.avartar = data.avartar;
          let bgUrl = this.$imagesUrl + data.background;
          //console.log('zyx', response.data, this.background, document.getElementById('nav'));
          document.getElementById('form').style.backgroundImage = "url("+bgUrl+")";
          let userUrl = this.$imagesUrl + data.avartar;
          document.getElementById('userImg').src = userUrl;
        }
      })
      if(document.body.clientHeight && document.body.clientHeight > 750){
        document.getElementById('form').style.overflowY = 'hidden';
      }
    },
    methods:{
      jumpToIndex(){
        this.$router.push('/');
      },
      changeBg(bg='bg5.png'){
        this.background = bg;
        let bgUrl = this.$imagesUrl + bg;
        //console.log('zyx', response.data, this.background, document.getElementById('nav'));
        document.getElementById('form').style.backgroundImage = "url("+bgUrl+")";
      },
      fileUpload(){
        let name = $($('#file')).val();
        let files = $($('#file'))[0].files[0];
        let formData = new FormData();
        formData.append("file", files);
        formData.append("name", name);
        if(!files.name || !/(.png|.jpg|.gif)$/.test(files.name)){
          //alert('文件格式不对');
          this.$notify.error({
            title: '错误',
            message: '文件格式不对，请传png，jpg或gif格式的图片'
          });
          /*let oldInput = document.getElementById("file");
          let newInput = document.createElement("input");
          newInput.type = "file";
          newInput.id = oldInput.id;
          newInput.name = oldInput.name;
          newInput.className = oldInput.className;
          newInput.style.cssText = oldInput.style.cssText;
          oldInput.parentNode.replaceChild(newInput, oldInput);*/
          this.$refs['fileBtn'].value = '';
          return false;
        }
        let that = this;
        $.ajax({
          url: that.$baseUrl + 'userFile',
          type: 'POST',
          data: formData,
          processData: false,
          contentType: false,
          dataType: 'json',
          success:function (res) {
            console.log('zyx', res);
            if(res.res){
              console.log(that.$imagesUrl + res.file_name);
              document.getElementById('userImg').src = that.$imagesUrl + res.file_name;
              that.avartar = res.file_name;
            }
            that.$refs['fileBtn'].value = '';
            that.$notify({
              title: '成功',
              message: '图片上传成功',
              type: 'success'
            });
          }
          ,error:function (res) {
            console.log('zyx', res);
            that.$refs['fileBtn'].value = '';
            that.$notify.error({
              title: '错误',
              message: '图片上传失败，请重新上传'
            });
          }
        });
        console.log(name, files);
      },
      doFileUpload(){
        console.log($('#file'));
        this.isChangeUnwrittenInformation = true;
        $('#file')[0].click();
      },
      confirmInfo(){
        console.log('uuu', this.background, this.avartar, this.name, this.content);
        if(this.isChangeUnwrittenInformation){
          this.$message({
            message: '被禁止的信息不能被修改',
            type: 'warning'
          });
          return false;
        }
        const isLoading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$rq({
          method: 'post',
          url: '/userInfo',
          data: qs.stringify({
            background: this.background,
            avartar: this.avartar,
            name: this.name,
            content: this.content
          }),
          //headers: {'contentType': 'application/x-www-form-urlencoded'}
        }).then((res) => {
          if(res.data && res.data.res){
            this.$notify({
              title: '成功',
              message: '个人信息修改成功',
              type: 'success'
            });
            this.$router.push('/');
          }else {
            this.$notify.error({
              title: '错误',
              message: '个人信息修改失败，请重新提交'
            });
          }
          isLoading.close();
        })
        .catch((err) => {
          console.error(err);
          this.$notify.error({
            title: '错误',
            message: '个人信息修改失败，请重新提交'
          });
          isLoading.close();
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .form{
    height: 100%;
    width: 100%;
    background: url("../assets/bg4.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;
    overflow-x: hidden;
    .paper{
      width: 700px;
      height: 700px;
      background: white;
      background: url("../assets/paper.png") no-repeat;
      background-size: 100% 100%;
      box-shadow:0 0 50px 50px #000000;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      .reback{
        position: absolute;
        top: 30px;
        left: 30px;
        cursor: pointer;
        svg{
          width: 60px;
          height: 60px;
        }
        &:hover{
          top: 25px;
          left: 25px;
          svg{
            width: 70px;
            height: 70px;
          }
        }
      }
      .title{
        margin-top: 45px;
        font-size: 26px;
        font-weight: bold;
      }
      .item{
        width: 100%;
        margin-top: 40px;
        display: flex;
        align-items: center;
        .mini-title{
          margin-left: 40px;
          margin-right: 20px;
          font-size: 22px;
        }
        .mini-content{
          display: flex;
          align-items: center;
          flex-grow: 1;
          .item-img{
            padding: 10px;
            background: #666;
            border-radius: 10px;
            display: flex;
            align-items: center;
            position: relative;
            margin-right: 15px;
            cursor: pointer;
            &:last-child{
              margin-right: 0px;
            }
            img{
              width: 130px;
              border-radius: 10px;
            }
            .el-icon-success{
              color: #666;
              position: absolute;
              right: 15px;
              bottom: 15px;
              font-size: 20px;
            }
          }
          .choose{
            background: #0acf0a;
            .el-icon-success{
              color: #0acf0a;
            }
          }
          .avartar{
            padding: 10px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            background: #0acf0a;
            img{
              width: 100px;
              height: 100px;
              border-radius: 50%;
            }
          }
          #file{
            display: none;
          }
          .fileBtn{
            height: 45px;
            margin-left: 30px;
            //background: #705333;
            background: #a1a1a1;
            color: white;
            border: none;
          }
          .myName{
            //border: #705333 solid 1px;
            /*&:focus-within{
              border: 1px solid #000000;
              border-radius: 4px;
            }*/
            width: 100%;
            margin-right: 30px;
          }
          .description{
            ::v-deep textarea{
              height: 60px;
            }
          }
        }
      }
      .action{
        margin-top: 40px;
        .actionBtn{
          background: #705333;
          color: white;
          border: none;
          width: 100px;
          &:first-child{
            margin-right: 50px;
          }
        }
      }
    }
  }
</style>
